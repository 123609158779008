import React from "react";
import SearchEngine from "./Core"
import "./App.css";
import { initializeMixpanel } from "./Core/utils/mixpanel";

initializeMixpanel();

function App() {
  return (
    <SearchEngine />
  )
}

export default App;
