import mixpanel from 'mixpanel-browser';
import { VERSION_NUMBER } from '../constants/misc';

export const initializeMixpanel = () => {
  const config = {
    'loaded': () => {
      const params = new URLSearchParams(window?.location?.search);
      const source = params?.get('source');
      mixpanel.register({
        'web_app_version': VERSION_NUMBER,
        source,
      });
    }
  }
  //staging
  // mixpanel.init('6b810a5add7f427cc6c6e0232d0302e8', config);
  //production
  mixpanel.init('efc22086339457b6b89b7b9b17bb82d9', config);
};